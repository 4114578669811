import * as React from "react";
import { Terms, Layout } from "../components";
import Seo from "../components/Seo";

const TermsPage = () => {
  return (
    <Layout>
      <Seo
        title="利用規約"
        description="当社が定める利用規約をご確認いただけます。"
      />
      <Terms />
    </Layout>
  );
};

export default TermsPage;
